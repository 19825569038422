import React from "react";
import { EmployeeServiceType } from "../../../api/apiEmployees";
import { EmployeeAdditional, TitleRows } from "../../../components";
import { differenceWithToday, formatParseDate, timeString } from "../../../utils/workingWithDates";
import { PlanedDate } from "../../CalendarPages/CalendarDayPage/PlanedDate/PlanedDate";
import { SettingsTableRowType } from "../../../uiKit/Table/TableBodyRow/TableBodyRow";
import { OrganizationSettingsType } from "../../../api/apiVaccination";

export const useTable = (
  canViewCard: boolean,
  hideDepartmentCol: boolean,
  settings?: OrganizationSettingsType
) => {
  const access = !!settings?.access;
  const agreement = !!settings?.agreement;

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Сотрудник", name: "fullName" },
    ...(hideDepartmentCol ? [] : [{ id: 3, title: "Подразделение", name: "department" }]),
    ...(access ? [{ id: 4, title: "Допуск", name: "access" }] : []),
    ...(agreement ? [{ id: 5, title: "Согласие", name: "agreement" }] : []),
    { id: 6, title: "Мед.отвод", name: "exemption" },
    { id: 7, title: "Дата предыдущей вакцинации ", name: "lastDate" },
    { id: 8, title: "Плановая вакцинация", name: "planeDate" },
    { id: 9, title: "Запись", name: "appointment" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1.5fr" },
    ...(hideDepartmentCol ? [] : [{ titleId: 3, size: "1fr" }]),
    ...(access ? [{ titleId: 4, size: "0.5fr" }] : []),
    ...(agreement ? [{ titleId: 5, size: "0.5fr" }] : []),
    { titleId: 6, size: "0.5fr" },
    { titleId: 7, size: "1fr" },
    { titleId: 8, size: "1fr" },
    { titleId: 9, size: "1fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const getRows = (date: string, time: string, place: string) => [
    { title: "Дата:", description: date },
    { title: "Время:", description: time },
    { title: "Место:", description: place },
  ];

  const object = (datum: EmployeeServiceType, number?: number): ObjectType => {
    const { employee, planned, appointment, settings, previous, healthCenter } = datum;

    const { time, date } = appointment || {};
    const healthCenterName = healthCenter?.name ?? "—";
    const { startTime, endTime } = time || {};

    const isOverdue = planned ? differenceWithToday(planned, "days") > 0 : false;

    return {
      ...(number ? { number: number } : {}),
      fullName: (
        <EmployeeAdditional
          employee={employee}
          canView={canViewCard}
          age="date"
          position={(employee.position as unknown as string) ?? "—"}
        />
      ),
      ...(hideDepartmentCol ? {} : { department: employee?.mainDepartment?.shortName ?? "—" }),
      ...(access ? { access: settings?.access ? "Да" : "Нет" } : {}),
      ...(agreement ? { agreement: settings?.agreement ? "Да" : "Нет" } : {}),
      exemption: settings?.exemptionId ? "Да" : "Нет",
      lastDate: previous ? formatParseDate(previous) : "—",
      planeDate: planned ? <PlanedDate date={planned} isOverdue={isOverdue} /> : <>—</>,
      appointment:
        date && startTime && endTime ? (
          <TitleRows
            rows={getRows(formatParseDate(date), timeString(startTime, endTime), healthCenterName)}
            isRows
          />
        ) : (
          <>—</>
        ),
      props: { background: isOverdue ? "error" : "default" },
    };
  };

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

export type ObjectType = {
  number?: number;
  fullName: JSX.Element;
  department?: string;
  access?: string;
  agreement?: string;
  exemption: string;
  lastDate: string;
  planeDate: JSX.Element;
  appointment: JSX.Element;
  props?: SettingsTableRowType;
};
