import { useUpdateAtom } from "jotai/utils";
import React from "react";
import { AppointmentType } from "../../../../api/apiAppointment";
import { EmployeeModuleType } from "../../../../api/apiEmployees";
import { VaccinationFactType } from "../../../../api/apiVaccination";
import { addFormState } from "../../../../atoms/atoms";
import { EmployeeAdditional } from "../../../../components";
import { SettingsTableRowType } from "../../../../uiKit/Table/TableBodyRow/TableBodyRow";
import { differenceWithToday, formatParseDate } from "../../../../utils/workingWithDates";
import { PlanedDate } from "../PlanedDate/PlanedDate";
import { UserSettingsCheckbox } from "../UserSettingsCheckbox/UserSettingsCheckbox";
import {
  currentFormRowState,
  currentTypeFormState,
  modalDataAppointmentState,
  modalDataFactState,
} from "../utils/atoms";
import { QueryKeyType } from "../../../../utils/useQuery";
import { VaccinationRecordFact } from "../VaccinationRecordFact/VaccinationRecordFact";

export const useTable = (
  access: boolean | undefined,
  agreement: boolean | undefined,
  canEdit: boolean,
  infection: string | undefined,
  queryKey: QueryKeyType,
  canViewProfile: boolean,
  dayDate: string | undefined
) => {
  // ------------------------------ АТОМЫ

  const setCurrentUserRow = useUpdateAtom(currentFormRowState);
  const setIsOpenAddForm = useUpdateAtom(addFormState);
  const setModalDataAppointment = useUpdateAtom(modalDataAppointmentState);
  const setModalDataFact = useUpdateAtom(modalDataFactState);
  const setCurrentForm = useUpdateAtom(currentTypeFormState);

  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Сотрудник", name: "employee" },
    ...(access === undefined || access ? [{ id: 3, title: "Допуск", name: "access" }] : []),
    ...(agreement === undefined || agreement
      ? [{ id: 4, title: "Согласие", name: "agreement" }]
      : []),
    { id: 5, title: "Медотвод", name: "exemption" },
    { id: 6, title: "Прошлая вакцинация", name: "past" },
    { id: 7, title: "Плановая вакцинация", name: "plan" },
    { id: 8, title: "Запись на вакцинацию", name: "record" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    ...(canEdit ? [{ titleId: null, size: "56px" }] : []),
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1.5fr" },
    ...(access === undefined || access ? [{ titleId: 3, size: "1fr" }] : []),
    ...(agreement === undefined || agreement ? [{ titleId: 4, size: "1fr" }] : []),
    { titleId: 5, size: "1fr" },
    { titleId: 6, size: "1fr" },
    { titleId: 7, size: "1fr" },
    { titleId: 8, size: "2.5fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ВАКЦИНАЦИЯ

  const onClickAppointment = (datum: EmployeeModuleType) => (appointment?: AppointmentType) => {
    setIsOpenAddForm(!appointment);
    setModalDataAppointment(appointment);
    setCurrentUserRow(datum);
    setCurrentForm("appointment");
  };

  const onDeleteAppointment = (appointment?: AppointmentType) => {
    setModalDataAppointment(appointment);
    setCurrentForm("appointment-deletion");
  };

  // ------------------------------ ТАБЛИЦА - ФАКТ ВАКЦИНАЦИИ

  const onClickFact = (datum: EmployeeModuleType) => (fact?: VaccinationFactType) => {
    const appointment = datum.appointmentVaccinations?.find(
      ({ appointment }) =>
        appointment && dayDate && formatParseDate(appointment.date) === formatParseDate(dayDate)
    )?.appointment;

    setIsOpenAddForm(!fact);
    setCurrentUserRow({ ...datum, appointment });
    setModalDataFact(fact);
    setCurrentForm("fact");
  };

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const futureDate = !!dayDate && differenceWithToday(dayDate, "days") < 0;
  const todayDate = !!dayDate && differenceWithToday(dayDate, "days") == 0;

  const object = (datum: EmployeeModuleType, number?: number): ObjectType => {
    const { employee, vaccinationSettings } = datum;
    const { planned, previous, appointmentVaccinations } = datum;
    const { exemptionId, access: accessUser, agreement: agreementUser } = vaccinationSettings || {};

    const isOverdue = planned ? differenceWithToday(planned, "days") > 0 : false;

    const canEditAppointment = !!(
      (access ? accessUser : true) &&
      (agreement ? agreementUser : true) &&
      canEdit
    );

    return {
      ...(number ? { number } : {}),
      employee: (
        <EmployeeAdditional
          employee={employee}
          canView={canViewProfile}
          position={employee.positionName}
          department={employee.mainDepartment.shortName ?? "—"}
        />
      ),
      access: (
        <UserSettingsCheckbox
          settings={vaccinationSettings}
          field="access"
          employeeId={employee.id}
          infectionId={infection ?? ""}
          queryKey={queryKey}
          enabled={canEdit}
        />
      ),
      agreement: (
        <UserSettingsCheckbox
          settings={vaccinationSettings}
          field="agreement"
          employeeId={employee.id}
          infectionId={infection ?? ""}
          queryKey={queryKey}
          enabled={canEdit}
        />
      ),
      exemption: exemptionId ? "Да" : "Нет",
      past: previous ? formatParseDate(previous) : "—",
      plan: <PlanedDate date={planned} isOverdue={isOverdue} />,
      record: (
        <VaccinationRecordFact
          onEditAppointment={onClickAppointment(datum)}
          onDeleteAppointment={onDeleteAppointment}
          onEditFact={onClickFact(datum)}
          futureDate={futureDate}
          todayDate={todayDate}
          canEdit={canEdit}
          canEditAppointment={canEditAppointment}
          appointmentsFacts={appointmentVaccinations}
          date={dayDate}
        />
      ),

      props: { background: isOverdue ? "error" : "default" },
    };
  };

  return {
    titlesInit,
    gridsInit,

    object,
  };
};

type ObjectType = {
  number?: number;
  employee: JSX.Element;
  access: JSX.Element;
  agreement: JSX.Element;
  exemption: string;
  past: string;
  plan: JSX.Element;
  record?: JSX.Element;
  fact?: JSX.Element;
  props?: SettingsTableRowType;
};
