import { departmentsStatAllState, departmentsStatsState } from "../../utils/atoms";
import React, { ReactNode } from "react";
import { Skeleton } from "../../../../uiKit";
import { ErrorText, InfoText } from "../../../../uiKit/ErrorBoundary/ErrorBoundary.styles";
import { unknownErrorText } from "../../../../utils/getErrorMessage";
import { colors } from "../../../../styles";
import { Chart as ChartType } from "chart.js";
import { ChartDepartmentHookProps } from "../../components/DepartmentChart/DepartmentChart";
import { useLoadableData } from "../../../../utils/useLoadableData";
import { useAtomValue } from "jotai/utils";

export const useDiagram = (props: ChartDepartmentHookProps) => {
  const { filters } = props;
  // ------------------------------ АТОМЫ

  const {
    data: statsForDepartments,
    loadableData,
    totalCount,
  } = useLoadableData(departmentsStatsState, []);
  const statsForDepartmentsAll = useAtomValue(departmentsStatAllState);

  // ------------------------------ ДАННЫЕ - ВСЕ

  const dataWrapper = (component: ReactNode) =>
    loadableData.state === "loading" ? (
      <Skeleton forElement="card" />
    ) : loadableData.state === "hasError" ? (
      <ErrorText>{unknownErrorText}</ErrorText>
    ) : !filters.infection ? (
      <InfoText>Выберите заболевание</InfoText>
    ) : !statsForDepartments.length ? (
      <InfoText>Нет данных</InfoText>
    ) : (
      component
    );

  // ------------------------------ ДИАГРАММА - ДАННЫЕ

  const getDatasets = (id: string, healthIndex: number, gradient?: CanvasGradient) => [
    {
      label: id,
      data: [healthIndex, 100 - healthIndex],
      backgroundColor: [gradient, colors.transparent],
      borderRadius: 124,
      borderWidth: 4,
      borderColor: [colors.grayscaleInput, colors.transparent],
      hoverBorderColor: colors.transparent,
    },
  ];

  // ------------------------------ ДИАГРАММА - ПЛАГИНЫ

  const getPlugins = (healthIndex: number) => [
    // все волшебные числа образовались из позиционирования текста относительно верха контейнера в макете минус корректировка на реальность
    {
      id: "health-index",
      afterDraw: (chart: ChartType) => {
        const context = chart.ctx;
        context.save();
        context.textAlign = "center";
        context.font = "500 42px sans-serif";
        context.fillStyle = colors.black;
        const text = String(healthIndex.toFixed(1));
        const x = chart.width / 2;
        const y = 96;
        context.fillText(text, x, y);
        context.restore();
      },
    },
    {
      id: "2",
      afterDraw: (chart: ChartType) => {
        const context = chart.ctx;
        context.save();
        context.textAlign = "center";
        context.font = "400 13px sans-serif";
        context.fillStyle = colors.grayscaleLable;
        const text = "Индекс";
        const x = chart.width / 2;
        const y = 120;
        context.fillText(text, x, y);
        context.restore();
      },
    },
    {
      id: "3",
      afterDraw: (chart: ChartType) => {
        const context = chart.ctx;
        context.save();
        context.textAlign = "center";
        context.font = "400 13px sans-serif";
        context.fillStyle = colors.grayscaleLable;
        const text = `${filters.page === "infection" ? "заболеваемости" : "вакцинации"}`;
        const x = chart.width / 2;
        const y = 136;
        context.fillText(text, x, y);
        context.restore();
      },
    },
  ];

  // ------------------------------ ДИАГРАММА - ОПЦИИ

  const getOptions = (healthIndex: number) =>
    ({
      cutout: 100 - (30 * 100) / 100,
      // (100) вся окружность (%)
      // ((30 * 100) / 100) - вычисление процента, который занимает полоса диаграммы
      // (30) - ширина окружности
      // (/ 100) - половина размера окружности
      rotation: 90 - 360 * (healthIndex / 100),
      animation: {
        duration: 2000,
        easing: "easeOutBounce",
      },
      animations: {
        startAngle: {
          from: 0,
        },
        endAngle: {
          from: 0,
        },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          position: "average",
          backgroundColor: colors.grayscaleBeautifulBlackOpacity,
          bodyColor: colors.white,
          bodyFont: { size: 16, weight: "400" },
          padding: 16,
          cornerRadius: 8,
          displayColors: false,
          callbacks: { label: ({ formattedValue }) => formattedValue },
        },
      },
    } as OptionsType);

  return {
    statsForDepartments,
    statsForDepartmentsAll,
    dataWrapper,

    getDatasets,
    getPlugins,
    getOptions,

    loadableData,
    totalCount,
  };
};

export type OptionsType = {
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem: { formattedValue: string }) => string | string[];
      };
    };
  };
};
