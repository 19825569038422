import { FormikValues } from "formik";
import { useAtomValue } from "jotai/utils";
import React, { useEffect } from "react";
import * as atoms from "../../atoms/atoms";
import { unknownErrorText } from "../../utils/getErrorMessage";
import { useUserSettings } from "../../utils/useUserSettings";
import { ErrorText, InfoText } from "../ErrorBoundary/ErrorBoundary.styles";
import { Form } from "../Form";
import { Loader } from "../Loader";
import { Skeleton } from "../Skeleton";
import { Container, TableWrapper } from "./Table.styles";
import { TableBodyRow } from "./TableBodyRow";
import { TableHeadRow } from "./TableHeadRow";
import { TableTitleRow } from "./TableTitleRow";
import { GridsType, TitlesType, useTable } from "./useTable";
import { Loadable } from "../../utils/useLoadableData";
import { EntitiesType } from "../../api/apiRequest";
import { SettingsTableRowType } from "./TableBodyRow/TableBodyRow";
import { FormMethodsType } from "../Form/Form";

const { statusCreateOrUpdateState, statusDeletionState } = atoms;

export const Table = <DATUM extends { id?: string }, OBJ, FLDS extends FormikValues, VOBJ>(
  props: TablePropsType<DATUM, OBJ, FLDS, VOBJ>
) => {
  const { methods } = props;
  const { needPagination = false, needScroll = false, panelHeight = "0" } = props;
  const { isPageWithWideTable = false, needRightBlock = false } = methods;

  const statusDeletion = useAtomValue(statusDeletionState);
  const statusCreate = useAtomValue(statusCreateOrUpdateState);

  const { data, loadableData, canEdit = false, notEditableIds, tableSettings } = methods;
  const { tableWrapper } = tableSettings || {};
  const { object, modalData, tableTitle, gridsInit, titlesInit } = methods;

  const { setUserSettings } = useUserSettings(methods);

  const { getGrids, setSelectedRows } = useTable<OBJ>();

  useEffect(() => {
    return () => setSelectedRows([]);
  }, []);

  if (!Array.isArray(data)) return null;

  const dataIds = data
    .filter(({ id }) => (notEditableIds && id ? !notEditableIds.includes(id) : true))
    .map((datum, index) => datum.id ?? index.toString());

  if (!data || !titlesInit || !gridsInit || !object) return null;

  const dataWrapper = (component: JSX.Element) =>
    loadableData ? (
      loadableData.state === "loading" ? (
        isPageWithWideTable ? (
          <Skeleton forElement="wideTable" grids={getGrids(gridsInit)} />
        ) : (
          <Skeleton forElement="table" grids={getGrids(gridsInit)} canEdit={canEdit} />
        )
      ) : loadableData.state === "hasError" ? (
        <ErrorText>{unknownErrorText}</ErrorText>
      ) : (isPageWithWideTable ? object.length === 0 : data.length === 0) ? (
        <InfoText>Нет данных</InfoText>
      ) : tableWrapper ? (
        tableWrapper(component)
      ) : (
        component
      )
    ) : data.length === 0 ? (
      <InfoText>Нет данных</InfoText>
    ) : tableWrapper ? (
      tableWrapper(component)
    ) : (
      component
    );

  return (
    <TableWrapper needPagination={needPagination} needScroll={needScroll} panelHeight={panelHeight}>
      <Container
        needPagination={needPagination}
        needScroll={needScroll}
        needRightBlock={needRightBlock}
      >
        {(statusDeletion || statusCreate) && <Loader over />}

        <TableHeadRow
          titlesInit={titlesInit}
          gridsInit={gridsInit}
          ids={dataIds}
          needCheck={gridsInit[0].size === "56px"}
          needMenu={gridsInit[gridsInit.length - 1].size === "56px"}
          setUserSettings={(selectedColumnNumbers) => setUserSettings(selectedColumnNumbers)}
        />

        {tableTitle ? <TableTitleRow text={tableTitle} /> : null}

        {dataWrapper(
          <>
            {data.map((datum, i) => (
              <TableBodyRow
                key={i}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                id={datum.id!}
                datum={datum}
                number={i}
                methods={methods}
                needCheck={gridsInit[0].size === "56px"}
                needMenu={gridsInit[gridsInit.length - 1].size === "56px"}
                needPagination={needPagination}
              />
            ))}
          </>
        )}
      </Container>

      {canEdit && modalData ? <Form id={modalData.id} methods={methods} isEdit /> : null}
    </TableWrapper>
  );
};

export type TablePropsType<DATUM, OBJ, FLDS, VOBJ> = {
  methods: TableMethodsType<DATUM, OBJ> & FormMethodsType<DATUM, FLDS, VOBJ>;
  needScroll?: boolean;
  needPagination?: boolean;
  panelHeight?: string;
};

export type TableMethodsType<DATUM, OBJ> = {
  needRightBlock?: boolean;
  isPageWithWideTable?: boolean;

  data?: DATUM[];
  loadableData?: Loadable<EntitiesType<unknown>>;
  tableTitle?: string;
  questionText?: string | ((props: { entity?: string; count?: number }) => string);
  successText?: string;
  canEdit?: boolean;
  titlesInit?: TitlesType;
  gridsInit?: GridsType;
  object?: (datum: DATUM, number?: number) => OBJ & { props?: SettingsTableRowType };
  notEditableIds?: string[];
  tableSettings?: TableSettingsType;
};

export type TableSettingsType = {
  tableWrapper?: (component: JSX.Element) => JSX.Element;
  hideDelete?: boolean;
  hideEdit?: boolean;
  editButton?: TableButtonType;
};

export type TableButtonType = { icon?: JSX.Element; text?: string };
