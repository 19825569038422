import React from "react";
import { ReasonType } from "../../../api/apiDeletionReason";
import { getFullName } from "../../../utils/workingWithNames";
import { formatParseDate } from "../../../utils/workingWithDates";
import { Link, TitleRows } from "../../../components";
import { Reason } from "../Reason/Reason";
import { USER_VACCINATION_RECORDS } from "../../../constants/routes";
import { NameType } from "../../../api/types";

export const useTable = () => {
  // ------------------------------ КОНСТАНТЫ

  const titlesInit = [
    { id: 1, title: "Сотрудник", name: "fio" },
    { id: 2, title: "Подразделение", name: "unit" },
    { id: 3, title: "Дата записи на вакцинацию", name: "date" },
    { id: 4, title: "Наименование заболевания против", name: "name" },
    { id: 5, title: "Тип", name: "type" },
    { id: 6, title: "Причина удаления", name: "reasons" },
    { id: 7, title: "Детали удаления", name: "detail" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "1fr" },
    { titleId: 2, size: "1fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "1fr" },
    { titleId: 6, size: "1fr" },
    { titleId: 7, size: "1fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const getRows = (abroadDate: string, administrator?: NameType) => [
    {
      title: "Отменил:",
      description: administrator
        ? `${administrator.lastName} ${administrator.firstName} ${administrator.middleName ?? ""}`
        : "—",
    },
    { title: "Дата отмены:", description: abroadDate },
  ];

  const object = (
    { employee, appointmentDate, infection, time, user, type, reason }: ReasonType,
    number?: number
  ): ObjectType => ({
    ...(number ? { number } : {}),
    fio: (
      <Link to={USER_VACCINATION_RECORDS(employee.id)} active={1}>
        {getFullName({ nameParts: employee })}
      </Link>
    ),
    unit: employee?.mainDepartment?.shortName || "—",
    date: appointmentDate ? formatParseDate(appointmentDate) || "—" : "",
    name: infection.name,
    type: type === "APPOINTMENT" ? "Запись" : "Факт вакцинации",
    reasons: <Reason reason={reason} />,
    detail: <TitleRows rows={getRows(formatParseDate(time), user)} />,
  });

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

export type ObjectType = {
  fio: JSX.Element;
  unit: string;
  date: string;
  name: string;
  type: string;
  reasons: JSX.Element;
  detail: JSX.Element;
};
