import { izServicesApi } from "./api";
import { FormatDateType, PaginationType } from "./types";
import { AxiosResponse } from "axios";
import { FiltersType } from "../pages/InfographicPages/utils/atoms";
import { localPagination } from "../utils/localPagination";
import { EntitiesResponseType } from "./apiRequest";

const getProps = (props: OrganizationProps, infectionUrl: string, vaccinationUrl: string) => {
  const type = props.page;
  const data = {
    request: {
      startDate: props.startDate,
      endDate: props.endDate,
      infectionId: props.infection,
    },
  };

  return { data, ulr: type === "infection" ? infectionUrl : vaccinationUrl };
};

export const infographicOrganizationApi = () => ({
  getService: async (
    props: OrganizationProps
  ): Promise<AxiosResponse<OrganizationStatisticType>> => {
    const { data, ulr } = getProps(
      props,
      "diseaseStatisticsService",
      "vaccinationStatisticsService"
    );

    const response = await izServicesApi.post(`/${ulr}/get`, data);

    return { ...response, data: response.data.data };
  },
});

export const summaryDataInfographicApi = () => ({
  get: async (props: OrganizationProps): Promise<AxiosResponse<EmployeeStatistic>> => {
    const { data, ulr } = getProps(
      props,
      "diseaseSummaryStatisticsService",
      "vaccinationSummaryStatisticsService"
    );

    return izServicesApi.post(`/${ulr}/get`, data);
  },
});

export const infographicDepartmentApi = () => ({
  get: async (
    props: OrganizationProps & PartialPaginationType
  ): Promise<AxiosResponse<EntitiesResponseType<DepartmentStatsType>>> => {
    const { data, ulr } = getProps(
      props,
      "departmentDiseaseStatisticsService",
      "departmentVaccinationStatisticsService"
    );

    const cacheId = `infographicDepartment${
      props.type === "infection" ? "Disease" : "Vaccination"
    }`;

    return localPagination<DepartmentStatsType>(
      cacheId,
      izServicesApi.post(`/${ulr}/get`, data),
      props,
      (data) => ({ items: data.data.data, totalCount: data.data.data.length })
    );
  },
});

// получаемые значения

export type OrganizationStatisticType = Record<FormatDateType, number>;

export type EmployeeStatistic = {
  all: number;
  sick?: number;
  vaccinated?: number;
};

export type DepartmentStatsType = {
  shortName: string;
  departmentCode: string;
  all: number;
  number?: number;
};

// передаваемые значения

export type OrganizationProps = FiltersType & {
  startDate: string;
  endDate: string;
  isDepartment?: boolean;
};

export type PartialPaginationType = Partial<PaginationType>;
