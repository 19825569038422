import { EmployeeType } from "../../../../api/apiEmployees";
import { EmployeeAdditional, EmployeeStatus } from "../../../../components";
import React from "react";
import EmployeeMorbidityCard from "../../components/DiseasesCard/EmployeeMorbidityCard";
import { formatParseDate } from "../../../../utils/workingWithDates";

export const useTable = (canViewProfile: boolean) => {
  // ------------------------------ ТАБЛИЦА - ЗАГОЛОВКИ

  const titlesInit = [
    { id: 1, title: "№", name: "number" },
    { id: 2, title: "Сотрудник", name: "employee" },
    { id: 3, title: "Статус", name: "status" },
    { id: 4, title: "Дата обновления", name: "updateDate" },
    { id: 5, title: "Статистика заболеваемости", name: "statistic" },
  ];

  // ------------------------------ ТАБЛИЦА - ШИРИНЫ ТАБЛИЦ

  const gridsInit = [
    { titleId: 1, size: "76px" },
    { titleId: 2, size: "1.5fr" },
    { titleId: 3, size: "1fr" },
    { titleId: 4, size: "1fr" },
    { titleId: 5, size: "3fr" },
  ];

  // ------------------------------ ТАБЛИЦА - ДАННЫЕ ДЛЯ СТРОКИ

  const object = (employee: EmployeeType, number?: number): ObjectType => {
    return {
      ...(number ? { number: number } : {}),
      employee: (
        <EmployeeAdditional
          employee={employee}
          canView={canViewProfile}
          hideAge
          position={employee.position?.shortName ?? "—"}
        />
      ),
      status: <EmployeeStatus status={employee.status} />,
      updateDate: employee?.lastModifiedDate ? formatParseDate(employee.lastModifiedDate) : "—",
      statistic: <EmployeeMorbidityCard diseases={employee.disease} />,
    };
  };

  return {
    titlesInit,
    gridsInit,
    object,
  };
};

export type ObjectType = {
  number?: number;
  employee: JSX.Element;
  status: JSX.Element;
  updateDate: string;
  statistic: JSX.Element;
};
