import { useAtomValue } from "jotai/utils";
import React from "react";
import { utils, writeFile } from "sheetjs-style";
import * as Yup from "yup";
import { employeeServiceApi } from "../../../api/apiEmployees";
import { OrganizationsSettingsType } from "../../../api/apiVaccination";
import {
  IsCorrectStartEndDate,
  notJustSpaces,
  required,
  thisDateIsToCorrect,
} from "../../../constants/forValidationSchemes";
import { withoutSpaces } from "../../../constants/regexs";
import * as xlsxStyles from "../../../styles/xlsxStyles";
import { StartAndEndDatesInput } from "../../../uiKit";
import { MergedCellType } from "../../../uiKit/Filters/ExportFilters";
import {
  formatDateForBackend,
  formatParseDate,
  getPeriodString,
  now,
} from "../../../utils/workingWithDates";
import { getFullName } from "../../../utils/workingWithNames";
import { selectedInfectionState } from "../../CalendarPages/CalendarPage/utils/atoms";
import { absoluteInitialValues, FiltersType } from "../utils/atoms";
import { getClearedObject } from "../../../utils/getClearedObject";
import { filtersState } from "../../InfographicPages/utils/atoms";

export const useExport = (
  fields: (jsx: JSX.Element) => JSX.Element,
  infections: OrganizationsSettingsType,
  isStatistic: boolean,
  totalCount: number
) => {
  // ------------------------------ АТОМЫ

  const selectedInfectionStatistic = useAtomValue(selectedInfectionState);
  const filtersInfographic = useAtomValue(filtersState);

  const selectedInfection = isStatistic ? selectedInfectionStatistic : filtersInfographic.infection;
  const department = filtersInfographic.department;
  const fileName = isStatistic ? "Данные о вакцинации" : "Статистика вакцинации";

  // ------------------------------ ФОРМА - ПОЛЯ

  const fieldsDownload = fields(
    <>
      <StartAndEndDatesInput
        name={{ start: "startDate", stop: "endDate" }}
        label={{ start: "С", stop: "До" }}
        placeholder={{ start: "ДД.ММ.ГГГГ", stop: "ДД.ММ.ГГГГ" }}
        merge
        outsideLabel="Период"
      />
    </>
  );

  // ------------------------------ ФОРМА - НАЧАЛЬНЫЕ ЗНАЧЕНИЯ

  const absoluteInitialDownloadValues = absoluteInitialValues;

  const initialValuesDownload = { ...absoluteInitialDownloadValues };

  // ------------------------------ ФОРМА - СХЕМА ВАЛИДАЦИИ

  const validationSchemaDownload = Yup.object().shape({
    departmentCode: Yup.string().matches(withoutSpaces, notJustSpaces),
    hasAppointment: Yup.string().matches(withoutSpaces, notJustSpaces),
    access: Yup.string().matches(withoutSpaces, notJustSpaces),
    agreement: Yup.string().matches(withoutSpaces, notJustSpaces),
    plannedExpired: Yup.string().matches(withoutSpaces, notJustSpaces),
    startDate: Yup.date()
      .typeError(thisDateIsToCorrect)
      .test(IsCorrectStartEndDate)
      .required(required),
    endDate: Yup.date().typeError(thisDateIsToCorrect).required(required),
    plannedComes: Yup.boolean(),
  });

  // ------------------------------ ФОРМА - СКАЧИВАНИЕ

  const getFilteredData = async (filter: FiltersType) => {
    const { startDate, endDate } = filter;

    const args = {
      ...getClearedObject({
        ...filter,
        departmentCode: isStatistic ? filter.departmentCode : department,
      }),
      infectionId: selectedInfection,
      page: 1,
      size: totalCount,
      startDate: formatDateForBackend(startDate || now),
      endDate: formatDateForBackend(endDate || now),
    };

    return (await employeeServiceApi().getVaccinationData(args)).data.items;
  };

  // ------------------------------ ЭКСПОРТ - СТИЛИ

  const { charA, dataCenterStyle, dataStyle, headerStyle, subHeaderStyle, titleRowStyle } =
    xlsxStyles;

  const onSubmitDownloadFilters = async (filter: FiltersType) => {
    const employees = await getFilteredData(filter);
    const filterDepartment = filter.departmentCode;

    const infectionName =
      infections.find(({ infection }) => infection.id === selectedInfection)?.infection.name ?? "—";
    // ------------------------------ индексы заголовков

    const headerIndexes = [
      "personnelNumber",
      "employee",
      "birthDate",
      "position",
      "department",
      "access",
      "agreement",
      "pastDate",
      "exemption",
      "planedVaccinationDate",
      "date",
      "place",
    ];

    // ------------------------------ заголовки

    const headers = {
      personnelNumber: "ТН",
      employee: "Сотрудник",
      birthDate: "Дата рождения",
      position: "Должность",
      department: "Подразделение",
      access: "Допуск",
      agreement: "Согласие",
      pastDate: "Дата предыдущей вакцинации",
      exemption: "Медотвод",
      planedVaccinationDate: "Плановая вакцинация",
      date: "Дата записи",
      place: "Место",
    };

    // ------------------------------ строки с данными

    const rows = [] as { [K in keyof typeof headers]: string }[];

    employees.forEach((employeeModule) => {
      const { employee, planned, settings, appointment, previous, healthCenter } = employeeModule;

      const { date } = appointment || {};

      rows.push({
        personnelNumber: employee.personnelNumber,
        employee: getFullName({ nameParts: employee }),
        birthDate: formatParseDate(employee.birthDate),
        position: (employee?.position as unknown as string | undefined) ?? "—",
        department: employee?.mainDepartment?.shortName ?? "—",

        access: settings?.access ? "Да" : "Нет",
        agreement: settings?.agreement ? "Да" : "Нет",
        pastDate: previous ? formatParseDate(previous) : "—",
        exemption: settings?.exemptionId ? "Да" : "Нет",

        planedVaccinationDate: planned ? formatParseDate(planned) : "—",

        date: date ? formatParseDate(date) : "—",
        place: healthCenter?.name ?? "—",
      });
    });

    // ------------------------------ формирование книги

    const wb = utils.book_new();

    // ------------------------------ формирование листов

    const { startDate, endDate } = filter;

    const firstEmployeeDepartment = `${employees[0]?.employee?.mainDepartment?.name}` ?? "";
    const departmentName = isStatistic
      ? filterDepartment
        ? firstEmployeeDepartment
        : ""
      : firstEmployeeDepartment;

    const ws = utils.json_to_sheet(
      [
        {},
        {
          personnelNumber: `${fileName} ${departmentName ? `: ${departmentName}` : ""}`,
        },
        {
          personnelNumber: `Период: ${getPeriodString(startDate, endDate)}`,
        },
        {
          personnelNumber: `Заболевание: ${infectionName}`,
        },
        {},
        headers,
        ...rows,
      ],
      {
        header: [...headerIndexes],
        skipHeader: true,
      }
    );

    utils.book_append_sheet(wb, ws, fileName);

    // ------------------------------ ширины столбцов

    const widths = [10, 30, 20, 20, 20, 12, 15, 15, 12, 12, 12, 12];
    ws["!cols"] = widths.map((width) => ({ wch: width }));

    // ------------------------------ высоты строк

    ws["!rows"] = [{}, { hpt: 15 * 3 }, {}, {}, {}, { hpt: 15 * 3 }];

    // ------------------------------ объединения
    // ------------------------------ горизонтально объединённые ячейки с данными

    const horizontalMergers = [] as MergedCellType;

    for (const item of [0, 1, 2, 3, 4]) {
      horizontalMergers.push({
        s: { c: 0, r: item },
        e: { c: 12, r: item },
      });
    }

    // ------------------------------ объединение

    ws["!merges"] = [...horizontalMergers];

    // ------------------------------ заголовок

    ws["A2"].s = headerStyle;
    ws["A3"].s = subHeaderStyle;
    ws["A4"].s = subHeaderStyle;

    // ------------------------------ строки заголовков таблицы

    for (let i = 0; i < headerIndexes.length; i++) {
      const char = String.fromCharCode(charA + i);
      ws[`${char}6`].s = titleRowStyle;
    }

    // ------------------------------ ячейки с выравниванием по левому краю
    const startEmployee = 7;

    employees.forEach((_, index) => {
      ["B", "C", "E", "D", "K", "L"].forEach((char) => {
        ws[`${char}${startEmployee + index}`].s = dataStyle;
      });
    });

    // ------------------------------ ячейки с выравниванием по центру

    employees.forEach((_, index) => {
      ["A", "E", "F", "G", "H", "I", "J"].forEach((char) => {
        ws[`${char}${startEmployee + index}`].s = dataCenterStyle;
      });
    });

    // ------------------------------ скачивание файла

    writeFile(wb, `${fileName} ${departmentName} ${getPeriodString(startDate, endDate)}.xlsx`);
  };

  return {
    fieldsDownload,
    initialValuesDownload,

    onSubmitDownloadFilters,

    validationSchemaDownload,
    absoluteInitialDownloadValues,
  };
};
