import React, { FC } from "react";
import { useDiagram } from "../../ChartsPage/hooks/useDiagram";
import { CardsContainer } from "../../InfographicPage.styled";
import { DepartmentCard } from "../DepartmentCard/DepartmentCard";
import { DepartmentDiagram } from "../DepartmentDiagram/DepartmentDiagram";
import { FiltersType } from "../../utils/atoms";

export const DepartmentChart: FC<ChartDepartmentProps> = (props) => {
  const { onClick, filters, forExport } = props;
  const methods = useDiagram(props);

  const { statsForDepartments, dataWrapper, statsForDepartmentsAll } = methods;
  const type = filters.page;

  return (
    <CardsContainer>
      {dataWrapper(
        (forExport ? statsForDepartmentsAll : statsForDepartments).map(
          ({ departmentCode, all, number, shortName }, i) => {
            const index = number && all ? (number / all) * 100 : 0;

            return (
              <DepartmentCard
                key={i}
                count={all?.toString() ?? "—"}
                name={shortName}
                fullName={shortName}
                sick={number?.toString() ?? "—"}
                screenshotMode={false}
                onClick={() => onClick(departmentCode)}
                type={type}
              >
                <DepartmentDiagram id={departmentCode} healthIndex={index} {...methods} />
              </DepartmentCard>
            );
          }
        )
      )}
    </CardsContainer>
  );
};

export type ChartDepartmentHookProps = { filters: FiltersType; selectedOrganizationName: string };
export type ChartDepartmentProps = ChartDepartmentHookProps & {
  onClick: (department: string) => void;
  forExport?: boolean;
};
